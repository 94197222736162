<template>
  <div>
    <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
      persistent
      max-width="1000"
    >
      <!--begin::Modal dialog-->
      <div class="bg-white poppins">
        <!--begin::Modal header-->
        <div class="modal-header align-center">
          <h3 class="mb-0 font-weight-bolder">Add Buyer</h3>
          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-light-info"
            @click="dialog = false"
          >
            <span class="svg-icon">
              <v-icon size="22">mdi-close</v-icon>
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--begin::Modal header-->
        <!--begin::Modal body-->
        <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
          <!--begin::Body-->
          <form id="createForm" class="pa-3 pb-0">
            <v-autocomplete
              v-model.trim="$v.formData.store_id.$model"
              :error-messages="validateState('store_id') ? requiredMessage : ''"
              :items="stores"
              outlined
              item-name="text"
              item-value="index"
              dense
              clearable
              label="Select Store"
            ></v-autocomplete>
            <v-text-field
              v-model.trim="$v.formData.name.$model"
              :error-messages="validateState('name') ? requiredMessage : ''"
              label="Name"
              dense
              outlined
              clearable
            ></v-text-field>
            <v-text-field
              v-model.trim="$v.formData.email.$model"
              :error-messages="validateState('email') ? emailMessage : ''"
              label="Email"
              dense
              outlined
              clearable
            ></v-text-field>
            <v-text-field
              v-model.trim="$v.formData.password.$model"
              :error-messages="validateState('password') ? passwordMessage : ''"
              type="password"
              label="Password"
              dense
              outlined
              clearable
            ></v-text-field>
          </form>

          <!--end::Body-->

          <!--begin::Actions-->
          <div class="mb-4 d-flex flex-grow-1 align-end justify-center">
            <button
              type="reset"
              class="btn btn-light mr-3 px-5 py-3 ls1"
              @click="toggleModal"
            >
              Cancel
            </button>
            <button
              type="submit"
              class="btn btn-info px-5 py-3 ls1"
              @click="submitForm"
            >
              Submit
            </button>
          </div>
          <!--end::Actions-->
        </div>
        <!--end::Modal body-->
      </div>
      <!--end::Modal dialog-->
    </v-dialog>
  </div>
</template>

<script>
import { UPDATE_CREATE_DATA } from "@/core/services/store/buyers.module";
import Swal from "sweetalert2";
import { validationMixin } from "vuelidate";
import { required, email, minLength } from "vuelidate/lib/validators";
import { messages } from "@/core/constants/message";
import ApiService from "@/core/services/api.service";

export default {
  name: "AddBuyer",
  mixins: [validationMixin],
  validations: {
    formData: {
      store_id: { required },
      name: { required },
      email: { required, email },
      password: { required, minLength: minLength(8) },
    },
  },
  props: ["refresher", "pageLoader"],
  data: () => ({
    dialog: false,
    formData: {
      store_id: null,
      name: null,
      email: null,
      password: null,
    },
  }),
  beforeMount() {
    this.$store.dispatch(UPDATE_CREATE_DATA, {}).then(() => {});
  },
  computed: {
    requiredMessage: function () {
      return messages.requiredMessage;
    },
    emailMessage: function () {
      return messages.emailMessage;
    },
    passwordMessage: function () {
      return messages.passwordMessage;
    },
    stores: function () {
      if (this.$store.getters.getBUYERSStoresData) {
        return this.$store.getters.getBUYERSStoresData;
      }
      return undefined;
    },
  },
  mounted() {
    this.resetData();
  },
  methods: {
    toggleModal() {
      this.dialog = !this.dialog;
      this.resetData();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.formData[name];
      return $dirty ? $error : null;
    },
    submitForm() {
      this.$v.formData.$touch();
      if (this.$v.formData.$anyError) {
        return;
      }
      let data = this.convertToFormData();
      this.pageLoader(true);
      ApiService.post("/stores/portal/buyers/store", data)
        .then(() => {
          this.message(
            "Created",
            "The End User data has been created!",
            "success"
          );
          this.toggleModal();
          this.pageLoader(false);
          this.refresher();
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    convertToFormData() {
      let data = new FormData();
      for (var key in this.formData) {
        if (this.formData[key]) {
          data.append(key, this.formData[key]);
        } else {
          return null;
        }
      }
      return data;
    },
    message(title, message, icon) {
      Swal.fire({
        title: title,
        text: message,
        icon: icon,
        showConfirmButton: false,
        timer: 2500,
      });
    },
    resetData() {
      this.formData.store_id = null;
      this.formData.name = null;
      this.formData.email = null;
      this.formData.password = null;
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
  },
};
</script>
